.badge {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  gap: 30px;
  overflow-x: scroll;

  &__column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 250px;
  }

  &__card-bg1,
  &__card-bg2,
  &__card-bg3,
  &__card-bg4,
  &__card-bg5 {
    display: flex;
    gap: 20px;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.12);
    justify-content: space-between;
  }

  &__card-bg1 {
    color: rgba(66, 90, 112, 1);
    background-color: rgba(248, 248, 248, 1);
  }

  &__card-bg2 {
    color: rgba(149, 89, 30, 1);
    background-color: rgba(250, 227, 205, 1);
  }

  &__card-bg3 {
    color: rgba(55, 36, 143, 1);
    background-color: rgba(234, 231, 248, 1);
  }

  &__card-bg4 {
    color: #000000;
    background-color: rgba(183, 28, 28, 0.2);
  }

  &__card-bg5 {
    color: rgba(36, 101, 39, 1);
    background-color: rgba(212, 238, 226, 1);
  }

  &__number,
  &__number-bg1,
  &__number-bg2,
  &__number-bg3,
  &__number-bg4,
  &__number-bg5 {
    margin: auto;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    text-align: center;
  }

  &__number-bg1 {
    background-color: rgba(228, 231, 235, 1);
  }

  &__number-bg2 {
    background-color: rgba(255, 192, 132, 1);
  }

  &__number-bg3 {
    background-color: rgba(55, 36, 143, 1);
    color: rgba(234, 231, 248, 1);
  }

  &__number-bg4 {
    background-color: #b71c1c;
  }

  &__number-bg5 {
    background-color: rgba(36, 101, 39, 1);
    color: rgba(212, 238, 226, 1);
  }

  &__pointer {
    text-align: end;
    display: flex;
    align-items: center;
  }

  &__cursor {
    cursor: pointer;
    width: 4px;
    padding: 10px;
  }

  &__card-header {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px !important;
  }

  &__card-description {
    margin: 4px;
    padding-left: 20px;
  }

  p {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  &__subcard-info-normal,
  &__subcard-info-other,
  &__subcard-info-work {
    padding: 15px;
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.12);
    line-height: 25px;
    position: relative;
    border-left: 4px solid;
    margin-bottom: 0px;
    border-radius: 5px;
    font-size: 14px;
  }

  &__subcard-info-normal {
    border-left-color: rgba(65, 150, 68, 1);
  }

  &__subcard-info-other {
    border-left-color: rgba(183, 28, 28, 1);
  }

  &__subcard-info-work {
    border-left-color: rgba(244, 145, 54, 1);
  }

  &__subcard-info-lastupdate {
    text-align: end;
    font-size: 12px;
    color: rgba(190, 190, 190, 1);
  }

  &__subcard-info-normal,
  &__subcard-info-other,
  &__subcard-info-work,
  &__subcard-info-lastupdate {
    text-decoration: none;
    color: $black;
  }
}