.project-info {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 16px;
  }

  &__body {
    margin-bottom: 16px;
  }

  &__body-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  &__body-subheader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__body-subheader-flex {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-right: 16px;
  }

  &__body-subheader-flex > div {
    margin-right: 24px;
  }

  &__employee_wrapper {
    width: fit-content;
    padding: 25px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  &__file-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
  }

  &__file-btn-wrapper > button:not(:last-child) {
    margin-right: 16px;
  }

  &__file-btn-wrapper > div > button {
    padding: 6px 44px;
  }

  &__body-header > button:first-child {
    margin-right: 16px;
  }

  &__status-wrapper {
    display: grid;
    gap: 16px;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__billing-wrapper {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 32px;
  }

  &__status-icon {
    width: 20px;
    height: 20px;
    padding: 15px;
    margin-bottom: 16px;
    border-radius: 50%;
    border: 4px solid #ddd;
    background: linear-gradient(0deg, #e5e5e5, #e5e5e5), #ffffff;
  }

  &__status-title {
    font-size: 14px;
    line-height: 16px;
  }

  &__accordian1-wrapper {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__btn-wrapper > button:first-child {
    margin-right: 16px;
  }

  &__forminput-digit {
    outline: none;
    margin: 5px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid $silver;
  }
}

.css-ghsjzk-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 2px solid #419644 !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8.4px 14px !important;
}

.css-1q1u3t4-MuiTableRow-root {
  background-color: $vista-blue;
}

// .css-q8hpuo-MuiFormControl-root {
//   width: unset !important;
// }
