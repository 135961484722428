.inventory {
  &__under-header-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }

  &__under-header-datepicker-layout {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
  }
}

.inventory-container {
  box-shadow: 0px 2px 10px #e5e5e5;
  border-radius: 15px;
  padding: 30px 16px;
  margin: 0 0 32px 0;
}

.table-customrows-test {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.inventory-progress-bar {
  margin: 40px 0;
}

.inventoryMaster-active-cell {
  display: flex;
  background-color: rgba(200, 237, 220, 1);
  color: rgba(66, 147, 70, 1);
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 3px;
  align-items: center;
  padding: 3px 12px;
  width: 60px;
  height: 27px;
}

.inventoryMaster-notActive-cell {
  display: flex;
  background-color: rgba(196, 196, 196, 1);
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
  padding: 3px 12px;
  width: 60px;
  height: 27px;
}

.inventoryMaster-cancel-cell {
  display: flex;
  background-color: rgba(255, 162, 150, 1);
  color: rgba(181, 72, 57, 1);
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
  padding: 3px 12px;
  width: 60px;
  height: 27px;
}

.inventoryMaster-addItem-blankImg {
  background: #e5e5e5;
  border: 3px dashed #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  width: 150px;
  height: 150px;
}

.table-container {
  overflow: auto;

  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}

.inventory-product-scan-waiting {
  padding: 6px 12px;

  background: #ffa396;
  border-radius: 3px;
}

.inventory-difference {
  color: rgba(255, 0, 0, 0.87);
}

.inventory-barcode {
  all: unset;
  padding: 6px 12px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.4px;
  text-transform: uppercase;
  border: 1px solid rgba(36, 101, 39, 0.5);
  box-sizing: border-box;
  border-radius: 4px;

  color: #246527;
  cursor: pointer;
}

.inventory-add-icon {
  cursor: pointer;
}

.inventory-product-scan-success {
  padding: 6px 12px;

  background: #c8eddc;
  border-radius: 3px;
}

.inventory-sub-row-selected {
  display: table-row;
}

.inventory-sub-row {
  display: none;
}

.inventory-spacificInfo-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.2fr;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-weight: 600;
  background-color: rgba(143, 203, 177, 1);
  height: 50px;
  border-radius: 6px 6px 0px 0px;
}

.inventory-spacificInfo-table-rows {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.2fr;
  justify-items: center;
  margin: 20px 0;
  align-items: center;
  text-align: center;
}

.inventory-title-grid {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 30px;
}
