.config {
  .header-layout {
    margin: 24px 0;
    justify-content: space-between;
    display: flex;
  }

  @media only screen and (max-width: 250px) {
    .header-layout {
      display: grid;
    }
  }
}
