.accounting {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      margin: 16px 0;
    }
  }

  &__container {
    box-shadow: 0px 2px 10px #e5e5e5;
    border-radius: 15px;
    padding: 32px 16px;
    margin: 0 0 32px 0;

    &__field-layout {
      display: grid;
      grid-template-columns: 0.8fr 0.2fr;
    }

    &__full-field-layout {
      display: grid;
      grid-template-columns: 1fr;
    }

    @media only screen and (max-width: 800px) {
      &__field-layout {
        grid-template-columns: 1fr;
      }
    }
  }

  &__summaryItem {
    display: flex;
    gap: 2rem;
    align-items: center;

    p:first-child {
      flex-basis: 66%;
      white-space: nowrap;
      text-align: right;
    }
    p:nth-child(2) {
      flex-basis: 17%;
      text-align: right;
    }

    p:last-child {
      flex-basis: 17%;
      text-align: right;
    }
  }

  &__button-layout {
    display: inline-flex;
    gap: 8px;
  }

  @media only screen and (max-width: 600px) {
    &__button-layout {
      display: flex;
      width: 50%;
      margin: auto;
    }
  }

  @media only screen and (max-width: 200px) {
    &__button-layout {
      display: grid;
      gap: 8px;
    }
  }

  &__link-text {
    text-decoration: underline;
    color: #246527;
  }
}
