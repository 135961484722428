.good-receipt-main {
  .button-left-layout {
    display: flex;
    gap: 15px;
  }
  .button-right-layout {
    text-align: end;
  }
}

.good-receipt-add-main {
  .radio-layout {
    margin: 10px;
  }

  .button-layout {
    display: inline-flex;
    gap: 10px;
  }

  @media only screen and (max-width: 600px) {
    .button-layout {
      display: grid;
      gap: 10px;
    }
  }
}
